@import '../../style/App.scss';

.hero {
  padding-top: 140px;
  width: 100%;
  padding-bottom: 60px;
  .hero-info {
    padding-top: 0;
    h1 {
      line-height: inherit;
      padding: 10px 0;
    }
    p {
      line-height: inherit;
      padding: 20px 0;
      margin-bottom:20px;
    }
    .buttonHero { 
      border: 2px solid $main-color;
      background-color: transparent;
     color: #000;
    padding: 15px 30px;
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: 800;
    }
    .buttonHero:hover {
      background-color: $main-color;
      border: 2px solid $main-color;
    }
  }

  .hero-image {
    max-width: 570px;
    height: auto;
    float: right;
  }
}



@media screen and (max-width: 1080px) {
  .hero {
    padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 990px) {
  .hero {
    .hero-info {
      text-align: center;
    }
  }
}