@import '../../style/App.scss';

#about {
  padding: 10px 0 30px 0  ;
}

@media screen and (max-width: 990px) {
  #about {
    text-align: center;
  }
}